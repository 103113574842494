@font-face {
  font-family: 'GT Pressura';
  src: url('/font/GT-Pressura-Mono-Light.eot');
  src: url('/font/GT-Pressura-Mono-Light.eot?#iefix') format('embedded-opentype'), url('/font/GT-Pressura-Mono-Light.woff') format('woff');
}
@font-face {
  font-family: 'GT Sectra';
  src: url('/font/GT-Sectra-Bold.eot');
  src: url('/font/GT-Sectra-Bold.eot?#iefix') format('embedded-opentype'), url('/font/GT-Sectra-Bold.woff') format('woff');
}
@font-face {
  font-family: 'GT Sectra';
  src: url('/font/GT-Sectra-Regular.eot');
  src: url('/font/GT-Sectra-Regular.eot?#iefix') format('embedded-opentype'), url('/font/GT-Sectra-Regular.woff') format('woff');
}

// Fonts
$type-sans: 'GT Pressura', sans-serif;
$type-serif: 'GT Sectra', sans-serif;

// Font weights
$extra-bold: 800;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $blue;
}

.h1,
.h2,
h1,
h2 {
  font-family: $type-serif;
}

.h3,
.h4,
h3,
h4 {
  font-family: $type-sans;
}

.h5,
.h6,
h5,
h6 {
  font-family: $type-sans;
  text-transform: uppercase;
}

.p,
p {
  font-family: $type-sans;
}

li {
  font-family: $type-sans;
  color: $blue;
}

a {
  font-family: $type-sans;
  color: $blue;
}

.a-text {
  border-bottom: 1px $blue solid;
}

.nav {
  &-inner {
    display: flex;
    flex-direction: row;
    padding-top: 4rem;
    @media screen and (min-width: $tablet-portrait-min) {
      // background: yellow;
      justify-content: flex-end;
    }
    @media screen and (max-width: $phone-landscape-max) {
      // background: tan;
      flex-wrap: wrap;
    }
    @media screen and (max-width: $phone-portrait-max) {
      // background: grey;
      flex-wrap: wrap;
    }
  }
  &-links {
    margin-right: 2rem;
    @media screen and (max-width: $phone-landscape-max) {
      padding: 2rem 0;
    }
  }
  &-links:last-child {
    margin-right: 0;
  }
}

.hero {
  &-img {
    width: 100%;
    height: 100%;
    height: 50rem;
    @media screen and (max-width: $phone-portrait-max) {
      height: 40rem;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .7;
    }
    &--position {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.logos {
  width: 75%;
  display: block;
  margin: auto;
  padding: 4rem 0;
}


.box {
  width: 15rem;
  margin: auto;
  padding: 0.75rem;
  &--blue {
    border: 2px solid $blue;
  }
  &--grey {
    border: 2px solid $grey-light;
  }
}


.square {
  width: 50%;
  height: 40rem;
  margin-right: 1rem;
}

html {
  // background-color: $white;
  color: $black;
  font-size: 16px;
  font-weight: $light;
  height: 100%;
  line-height: 1.5;
  position: relative;
}

*, *::after, *::before {
  box-sizing: border-box;
}

a, a:hover {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

@mixin clearfix {
  &::after {
    clear: both;
    content: ' ';
    display: block;
  }
}

body#page1 { background-color: $grey-light; }
body#page2 { background-color: $grey-light; }
body#page3 { background-color: $white-dark; }
